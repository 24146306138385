.box-contenidoITC {
    min-height: 54vh;
    width: 45vw;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    margin: auto 0;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.box-contenidosmall {
    min-height: 54vh;
    width: 37vw;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    margin: auto 0;
    align-items: start;
    justify-content: center;
    border-radius: 15px;
}

.box-contenido2 {
    min-height: 50vh;
    width: 30vw;
    height: 42vh;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    margin: auto 0;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}


.imagenLogo {
    object-fit: cover;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.flex-contenidoITC {
    width: 100%;
    min-height: 55vh;
    display: flex;
    gap: 4.5vw;
    justify-content: center;
    align-items: center;
}

.box-textoITC {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.texto-boxcITC {
    font-size: 1.8rem !important;
    text-align: justify;
    line-height: 150%;
    text-shadow: 1px 1px 2px gray;
    height: 100%;
}

.texto-boxc2 {
    font-size: 1.5rem !important;
    text-align: justify;
    line-height: 150%;
    text-shadow: 1px 1px 2px gray;
    height: 100%;
}
.texto-boxclistas {
    font-size: 1.1rem !important;
    text-align: justify;
    line-height: 150%;
    text-shadow: 1px 1px 2px gray;
    height: 100%;
}

.box-tituloc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-tituloc {
    text-shadow: 1px 1px 2px gray;
    font-size: 2rem !important;
    font-weight: bold !important;
}

.box-listatexto {
    width: 90%;
    height: auto;
}

@media (max-width: 768px) {
    .box-contenidoITC {
        width: 80vw;
        min-height: 40vh;
    }
    .box-contenido2 {
        width: 75vw;
        min-height: 35vh;
    }

    .box-contenidosmall {
        width: 72vw;
        min-height: 40vh;
        align-items: center;
    }

    .flex-contenidoITC {
        gap: 6vh;
        margin-top: 5vh;
    }

    .texto-boxcITC {
        font-size: 1.4rem !important;
        width: 100%;
    }
    .texto-boxc2 {
        font-size: 1.17rem !important;
        width: 100%;
    }
    .text-tituloc {
        font-size: 1.8rem !important;
    }
    .box-listatexto {
        width: 75%;
    }
    .texto-boxclistas {
        font-size: 1rem !important;
    }
    .flex-contenidoITC {
        margin-top: 1vh;
    }
}