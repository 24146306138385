/* styles.css */
.imagenLogo {
  object-fit: fill;
  position: relative;
  height: inherit;
  width: inherit;
  border-radius: 15px;
}

.containerCardsRotatory {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  height: 100%;
  margin: 0px auto;
}

.cardImage {
}

.cardTitle {
  color: #2ea44a;
  margin-bottom: 10px;
}

.cardText {
  color: #595456;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 100%;
  padding: 5%;
  background-color: white;
  text-align: center;
  justify-content: center;
}

.card img {
  width: 50%;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .containerCards {
    grid-template-columns: 1fr !important;
  }

  .cards-container {
    grid-template-columns: 1fr !important;
    width: 100% !important;
    gap: 10px;
  }

  .card {
    width: 100%;
    margin: 20px 10px;
  }

  .logo-image {
    max-width: 70%;
    max-height: 100%;
  }

  .logo-container {
    margin-bottom: 5vh;
  }
}
