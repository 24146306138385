@font-face {
  font-family: "Century Gothic" !important;
  src: url("../font/centurygothic.ttf") format("ttf");
  font-weight: normal;
}

.css-h8f6pa {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5) !important;
  height: 140px;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  background-color: #fff;
  border: 1px solid #555555;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 15px;
  line-height: 1.35135;
}
