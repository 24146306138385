.box-parentD {
    width: 100%;
    height: auto;
}

.flex-desoft {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    margin-top: 8vh;
    gap: 8vh;
    /* background-color: white; */
}

.texto-titulonegro {
    font-size: 2.7rem;
    font-weight: bold;
    color: #595456 !important;
    text-shadow: 1.5px 1.5px 1.5px gray !important;
}

.texto-titulocolor {
    font-size: 2.7rem;
    font-weight: bold;
    color: #0d67a0 !important;
    text-shadow: 1.5px 1.5px 1.5px gray !important;
}

.box-textotitulo {
    width: 75%;
    height: auto;
    text-align: center;
}

.box-titulo2 {
    width: 65%;
    height: auto;
}

.texto-titulo2 {
    color: #1e5ca9;
    text-align: center;
    font-size: 2.3rem;
    font-weight: bold;
    text-shadow: 1.5px 1.5px 1.5px gray;
    text-transform: uppercase;
}

.box-imagenmeto {
    width: 80vw;
    height: 60vw;
}

.imagenmeto {
    object-fit: fill;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.flex-herramientas {
    width: 90vw;
    height: auto;
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-top: 2vh;
    margin-bottom: 8vh;
}

.box-imgherramienta {
    width: 13vw;
    height: 26vh;
}

.img-herramienta {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .texto-titulonegro {
        font-size: 1.6rem !important;
    }

    .texto-titulocolor {
        font-size: 1.6rem !important;
    }

    .box-textotitulo {
        width: 85vw;
    }

    .texto-titulo2 {
        font-size: 2rem !important;
    }

    .flex-herramientas {
        /* flex-direction: column; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 2vh;
    }

    .box-imgherramienta {
        width: 30vw;
        height: 15vh;
        margin: 0 auto;
    }
    .flex-desoft {
        gap: 5vh;
    }
}