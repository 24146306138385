.flex-ciberseg {
    width: 100%;
    height: fit-content;
    display: flex;
    margin-top: 5vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6vh;
    margin-bottom: 5vh;
    background-color: white;
}

.box-titulo {
    width: 75%;
    height: 10vh;
}

.texto-titulo {
    color: #1e5ca9;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 1px 1px 2px gray;
}

.box-parent {
    width: 100%;
    height: auto;
    padding-bottom: 5vh;
}

@media (max-width: 768px) {
    .texto-titulo {
        font-size: 2.3rem;
    }

    .box-titulo {
        width: 80%;
    }
    .box-parent {
        padding-bottom: 2vh;
    }
}